import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Box } from "@material-ui/core";
import zhutravelimg from "../../assets/zhutravelimg.png";
const useStyles = makeStyles({
  titlefont: {
    color: "white",
    backgroundImage: "radial-gradient(#06eac0, transparent)",
    width: "100%",
    textAlign: "center",
    fontWeight: 900,
    marginTop: 91,
    paddingTop: 30,
  },
  imgheight: {
    maxHeight: 60,
  },
  dflex: {
    display: "flex",
    padding: 40,
    alignItems: "center",
  },
  imgsize: {
    height: 100,
  },
  mevrisheight: {
    height: 60,
  },
});

export default function GlobalBusiness() {
  const classes = useStyles();

  return (
    <Box className={classes.titlefont}>
      TRUSTED BY
      <Grid className={classes.dflex} spacing={4} container>
        <Grid xs={12} sm={3} md={6} lg={6} item>
          <img
            className={classes.imgheight}
            src="https://media.licdn.com/dms/image/C560BAQGQmhhmATdFrg/company-logo_100_100/0/1680016409852/veeveai_logo?e=1709769600&v=beta&t=ObuCr8y-myEPpLSLcZv5m-zpyU72OBbR6J7rn1Yp384"
          />
        </Grid>
        <Grid xs={12} sm={3} md={6} lg={6} item>
          <Box fontSize={60} color="white" fontFamily="cursive">
            Lavoro
          </Box>
        </Grid>

        <Grid xs={12} sm={3} md={6} lg={6} item>
          <img
            className={classes.imgheight}
            src="https://assets-global.website-files.com/659ff18fc5e5262659df89e8/65a55ef2004a978a3ca3731c_hubii-main-blue.svg"
          />
        </Grid>

        <Grid xs={12} sm={3} md={6} lg={6} item>
          <img
            className={classes.imgheight}
            src="https://media.licdn.com/dms/image/D4D0BAQEY_lMD2gCAHA/company-logo_200_200/0/1665061263152/packagexio_logo?e=1709769600&v=beta&t=w5ifExHeH_rvBwGK0ycnNpELoedN-2YnSZ43d5y-ckg"
          />
        </Grid>
        <Grid xs={12} sm={3} md={6} lg={6} item>
          <img
            className={classes.mevrisheight}
            src="https://cdn.shopify.com/s/files/1/0366/2325/3635/t/2/assets/logo.svg?v=16040664564285682502"
          />
        </Grid>
        <Grid xs={12} sm={3} md={6} lg={6} item>
          <img className={classes.imgsize} src={zhutravelimg} />
        </Grid>
      </Grid>
    </Box>
  );
}
